.empty-paginator {
    height: 0;
    border-bottom: none !important;
    .mat-mdc-paginator-container {
        display: none !important;
    }
}
.hide-empty-dashboard-line {
    border: none !important;
}

.mat-mdc-paginator .mat-mdc-paginator-range-label {
    color: var(--grey30);
    font-family: "Neue Montreal", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.64;
}

.mat-mdc-paginator .mat-mdc-paginator-page-size-label {
    color: var(--grey30);
    font-family: "Neue Montreal", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.64;
}

.mat-mdc-paginator .mat-mdc-select-value-text .mat-mdc-select-min-line {
    font-family: "Neue Montreal", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--offblack10);
}

.mat-mdc-paginator .mat-mdc-icon-button {
    svg {
        fill: var(--primary80);
    }
}

.mat-mdc-paginator .mat-mdc-icon-button[disabled][disabled] {
    svg {
        fill: var(--primary80);
        opacity: 0.3;
    }
}

.mat-mdc-paginator  .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
    background-color: var(--primary-background-color);
    border-radius: 4px;
}

.mat-mdc-paginator {
    .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
      border: none;
    }
}

.mat-mdc-paginator {
    .mat-mdc-select-arrow svg {
        display: none;
    }
    .mat-mdc-select-arrow {
        background-image: url('/assets/icons/arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 24px;
        height: 24px;
    }
}

.mat-column-online, .mat-column-terminate, .mat-column-contact, .mat-column-approve, .mat-column-profile, .mat-column-teacherProfile, .mat-column-studentProfile, .mat-column-actions {
    width: 8%;
}


