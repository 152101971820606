:root {
  --white: #FFFFFF;
  --black: #000000;
  --primary-background-color: #F2F2F2;
  --grey95: #F5F2F7;
  --grey30: rgba(21, 21, 21, 0.64);
  --primary80: #C97D70;
  --offblack10: #000;
  --offblack100: #151515;
  --white100: #FFF;
}
