@use '@angular/material' as mat;

$custom-palette: (
  50 : #f9efee,
  100 : #efd8d4,
  200 : #e4beb8,
  300 : #d9a49b,
  400 : #d19185,
  500 : #c97d70,
  600 : #c37568,
  700 : #bc6a5d,
  800 : #b56053,
  900 : #a94d41,
  A100 : #fffdfc,
  A200 : #ffcfc9,
  A400 : #ffa296,
  A700 : #ff8b7d,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);



$success: mat.define-palette($custom-palette);
$info: mat.define-palette($custom-palette);

$custom-theme: mat.define-light-theme(
    (
      color: (
        primary: $success,
        accent: $info,
      ),
    )
);

.custom-theme {
    @include mat.button-color($custom-theme);
    .mdc-button {
        border-color: mat.get-color-from-palette(mat.define-palette($custom-palette)) !important;
    }
    .mdc-checkbox__background {
        border-color: mat.get-color-from-palette(mat.define-palette($custom-palette)) !important;
    }
}

.form-field {
    .mdc-text-field {
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    .mat-mdc-form-field-infix {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    .mat-mdc-form-field-hint-wrapper {
        padding: 0;
    }
    .mdc-line-ripple {
        display: none;
    }
    .mat-mdc-form-field {
        width: 100%;
    }
    .mat-mdc-form-field-infix {
        min-height: 40px;
    }
    .mdc-text-field--filled .mdc-floating-label,  .mat-mdc-checkbox label, .question {
        color: var(--OffBlack10) !important;
        font-family: Neue Montreal, sans-serif;
        font-size: 16px;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
    .mat-mdc-form-field-subscript-wrapper {
        color: var(--OffBlack10);
        font-family: Neue Montreal, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    button {
        font-family: Neue Montreal, sans-serif;
        font-size: 16px;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.toolbar-form {
    .mat-mdc-chip-option {
        font-family: Neue Montreal, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}
.form-content {
    .chips-container {
        .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
            justify-content: center;
        }
        .mat-mdc-chip-set {
            max-width: 107.4px;
        }
        .mdc-evolution-chip__graphic {
                padding: 0 !important;
        }
        .mat-mdc-chip-set-stacked .mdc-evolution-chip__action--primary {
            justify-content: center;
        }
        .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
            padding-right: 0;
        }
    }
    .mat-mdc-form-field-error {
        font-size: 12px;
    }
    .mat-mdc-list-base {
        padding-top: 0;
    }
    .default-select-icon-color {
        .mat-mdc-select-arrow {
            color: var(--mat-select-enabled-arrow-color) !important;
        }
    }
}

a:not([href]) {
    cursor: pointer;
    color: mat.get-color-from-palette(mat.define-palette($custom-palette));
    text-decoration: underline;
}

.mdc-button.mat-mdc-raised-button.mat-primary {
    box-shadow: none !important;
}

.mdc-button {
    font-family: 'Neue Montreal', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.mdc-button.mat-mdc-raised-button.mat-primary:hover {
    background-color: #B06D62;
}

.mdc-button.mat-mdc-raised-button.mat-primary:active,
.mdc-button.mat-mdc-raised-button.mat-primary:focus {
    background-color: #965E54;
}

.mat-mdc-standard-chip {
    cursor: pointer;
    .mdc-evolution-chip__text-label {
        label {
            cursor: pointer;
        }
    }
}
[aria-disabled="true"].mat-step-header {
    .mat-step-icon {
        background-color: var(--grey95);
        color: var(--offblack10);
    }
}

.mat-stepper-vertical, .mat-stepper-horizontal {
    background-color: unset !important;
}
.mat-step-header:hover:not([aria-disabled]), .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused {
    background-color: unset !important;
}
.mat-step-header:hover[aria-disabled = true] {
    cursor: pointer !important;
    user-select: none;
}

.mat-step-header[aria-labelledby="disabled_af"] {
    pointer-events: none !important;
    cursor: not-allowed;
    user-select: none;
}

.mat-step-icon-content {
    font-family: 'Neue Montreal', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mat-mdc-raised-button:disabled {
    background-color: var(--primary-background-color,  #F2F2F2) !important;
    .mdc-button__label {
        opacity: 0.16;
        color: var(--offblack10) !important;
    }
}

.mdc-text-field--disabled .mdc-text-field__input {
    opacity: 0.2;
}

.desk-menu-item.mat-mdc-menu-item {
    color: var(--primary80);
    text-align: center;
    font-family: Neue Montreal, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

