
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.define-palette(mat.$indigo-palette);
$client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-light-theme((
  color: (
    primary: $client-primary,
    accent: $client-accent,
    warn: $client-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
 @include mat.all-component-themes($client-theme);


/* You can add global styles to this file, and also import other style files */

@import "assets/styles/fonts.css";
@import "assets/styles/variables";
@import "assets/styles/custom-theme";
@import "assets/styles/mat-button-variants";
@import "assets/styles/paginator";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

@media (max-width: 767px) {
    .modal-open {
        overflow: hidden;
    }
}

.cdk-global-overlay-wrapper .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none;
}

.mat-mdc-table .mat-mdc-header-cell, .mat-mdc-table .mat-mdc-row .mat-mdc-cell {
    border-color: var(--grey95) !important;
}

.mat-mdc-table .mat-table {
    border-color: var(--grey95) !important;
}

.link-url-icon {
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0.54);
}

